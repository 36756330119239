import UserIcon from '@material-ui/icons/People'

import UserList from './UserList'
import UserEdit from './UserEdit'

export default {
    list: UserList,
    edit: UserEdit,
    icon: UserIcon,
}
