import React from 'react'
import {
  Datagrid,
  List,
  TextField,
  ImageField,
  ReferenceField,
  EditButton,
} from 'react-admin'

import { ActionsToolbar } from '../components/ActionsToolbar'
import CustomDateField from '../components/CustomDateField'

const PetList = props => (
  <List
    {...props}
    bulkActionButtons={false}
    actions={<ActionsToolbar />}
    title="List of Pets"
  >
    <Datagrid>
      <TextField label="Name" source="name" />
      <ImageField label="Photo" source="image.src" />
      <TextField label="Animal" source="animal" />
      <TextField label="Breed" source="breed" />
      <TextField label="Gender" source="gender" />
      <CustomDateField source="birthday" displayFormat={'DD/MM/YYYY'} />
      <ReferenceField label="Owner" source="owner" reference="users">
        <TextField source="firstName" />
      </ReferenceField>
      <EditButton label="Edit" />
    </Datagrid>
  </List>
)

export default PetList
