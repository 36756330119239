import React from 'react'
import {
  TextField,
  TextInput,
  Edit,
  SimpleForm,
  DateField,
  EmailField,
  SelectInput,
  ReferenceField,
} from 'react-admin'

import { SaveToolbar } from '../components/SaveToolbar'

const UserEdit = props => (
  <Edit {...props} title="Edit user">
    <SimpleForm toolbar={<SaveToolbar />}>
      <TextInput label="First name" source="firstName" />
      <TextInput label="Last name" source="lastName" />
      <TextInput label="Mobile Number" source="mobileNumber" />
      <TextField label="Address" source="address.addressLine" />
      <EmailField label="Email" source="email" />
      <ReferenceField
        label="Referred From"
        source="referredBy"
        reference="users"
      >
        <TextField source="firstName" />
      </ReferenceField>
      <TextField label="Referral Code" source="usersReferralCode" />
      <SelectInput
        label="Subscription Type"
        source="subscriptionType"
        choices={[
          { id: 'Free', name: 'Free' },
          { id: 'Premium', name: 'Premium' },
        ]}
      />
      <DateField label="Registration Date" source="registrationDate" />
    </SimpleForm>
  </Edit>
)

export default UserEdit
