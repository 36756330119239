import React, { useState, useEffect, useCallback, CSSProperties } from 'react'
import { useVersion, useDataProvider } from 'react-admin'
import { Theme } from '@material-ui/core'

import PendingBadges from './PendingBadges'
import PendingQuests from './PendingQuests'

const styles = {
    flex: { display: 'flex' },
    flexColumn: { display: 'flex', flexDirection: 'column' },
    leftCol: { flex: 1, marginRight: '0.5em' },
    rightCol: { flex: 1, marginLeft: '0.5em' },
    singleCol: { marginTop: '1em', marginBottom: '1em' },
}

const Spacer = () => <span style={{ width: '1em' }} />
const VerticalSpacer = () => <span style={{ height: '1em' }} />

const Dashboard = () => {
    const [state, setState] = useState({})
    const version = useVersion()
    const dataProvider = useDataProvider()

    const fetchBadgesReview = useCallback(() => {
        dataProvider.getList('queue', {
            filter: { status: 'pending', type: 'badges' },
            sort: { field: 'date', order: 'DESC' },
            pagination: { page: 1, perPage: 10 },
        }).then(response => {
          if (response && response.data) {
            const { data = []} = response
            const nbPendingBadges = data.reduce((nb: number) => ++nb, 0)
            const pendingBadges = data.slice(0, Math.min(10, data.length))
            setState(state => ({ ...state, pendingBadges, nbPendingBadges }))
          }
        })
    }, [dataProvider])

    const fetchQuestsReview = useCallback(() => {
        dataProvider.getList('queue', {
            filter: { status: 'pending', type: 'quests' },
            sort: { field: 'date', order: 'DESC' },
            pagination: { page: 1, perPage: 10 },
        }).then(response => {
          if (response && response.data) {
            const { data = []} = response
            const nbPendingQuests = data.reduce((nb: number) => ++nb, 0)
            const pendingQuests = data.slice(0, Math.min(10, data.length))
            setState(state => ({ ...state, pendingQuests, nbPendingQuests }))
          }
        })
    }, [dataProvider])

    useEffect(() => {
        fetchBadgesReview()
        fetchQuestsReview()
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    const {
        nbPendingBadges,
        pendingBadges,
        nbPendingQuests,
        pendingQuests
    } = state
    return (
      <>
        <div style={styles.flex}>
          <PendingBadges
            nb={nbPendingBadges}
            badges={pendingBadges}
          />
          <PendingQuests
            nb={nbPendingQuests}
            quests={pendingQuests}
          />
        </div>
      </>
    )
    // return (
    //   <>
    //       <div style={styles.flex}>
    //           <div style={styles.leftCol}>
    //               <div style={styles.flex}>
    //                   <MonthlyRevenue value={revenue} />
    //                   <Spacer />
    //                   <NbNewOrders value={nbNewOrders} />
    //               </div>
    //               <div style={styles.singleCol}>
    //                   <OrderChart orders={recentOrders} />
    //               </div>
    //               <div style={styles.singleCol}>
    //                   <PendingOrders
    //                       orders={pendingOrders}
    //                       customers={pendingOrdersCustomers}
    //                   />
    //               </div>
    //           </div>
    //           <div style={styles.rightCol}>
    //               <div style={styles.flex}>
    //                   <PendingReviews
    //                       nb={nbPendingReviews}
    //                       reviews={pendingReviews}
    //                       customers={pendingReviewsCustomers}
    //                   />
    //                   <Spacer />
    //                   <NewCustomers />
    //               </div>
    //           </div>
    //       </div>
    //   </>
    // )
}

export default Dashboard
