import React, { cloneElement } from 'react'
import {
    useListContext,
    TopToolbar,
    CreateButton,
    // ExportButton,
    // Button,
    sanitizeListRestProps,
} from 'react-admin'
// import IconEvent from '@material-ui/icons/Event'

export const ActionsToolbar = (props) => {
    const {
        className,
        exporter,
        filters,
        maxResults,
        ...rest
    } = props
    const {
        // currentSort,
        resource,
        displayedFilters,
        filterValues,
        // hasCreate,
        basePath,
        // selectedIds,
        showFilter,
        // total,
    } = useListContext()
    return (
        <TopToolbar className={className} {...sanitizeListRestProps(rest)}>
            {filters && cloneElement(filters, {
                resource,
                showFilter,
                displayedFilters,
                filterValues,
                context: 'button',
            })}
            <CreateButton basePath={basePath} />
        </TopToolbar>
    )
}

// <ExportButton
//     disabled={total === 0}
//     resource={resource}
//     sort={currentSort}
//     filterValues={filterValues}
//     maxResults={maxResults}
// />
// {/* Add your custom actions */}
// <Button
//     onClick={() => { alert('Your custom action') }}
//     label="Show calendar"
// >
//     <IconEvent />
// </Button>
