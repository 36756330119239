import ToolIcon from '@material-ui/icons/Build'

import { ShowGuesser } from 'react-admin'
import ToolList from './ToolList'
import ToolCreate from './ToolCreate'
import ToolEdit from './ToolEdit'

export default {
    list: ToolList,
    create: ToolCreate,
    edit: ToolEdit,
    show: ShowGuesser,
    icon: ToolIcon,
}
