import PetIcon from '@material-ui/icons/ScatterPlot'

import PetList from './PetList'
import PetEdit from './PetEdit'

export default {
    list: PetList,
    edit: PetEdit,
    icon: PetIcon,
}
