import BrandIcon from '@material-ui/icons/Store'

import BrandList from './BrandList'
import BrandCreate from './BrandCreate'
import BrandEdit from './BrandEdit'
import BrandShow from './BrandShow'

export default {
    list: BrandList,
    create: BrandCreate,
    edit: BrandEdit,
    show: BrandShow,
    icon: BrandIcon,
}
