import React from 'react'
import {
  Edit,
  SimpleForm,
  TextInput,
  NumberInput,
} from 'react-admin'

const DailyStepGoalsInput = ({ record = {}, ...rest }) =>
  record && record.type === 'dog' ? (
    <NumberInput
      label="Daily Step Goals"
      source="dailyStepGoals"
      record={record}
      {...rest}
    />
  ) : null

const BreedEdit = props => (
  <Edit {...props} title="Edit Breed">
    <SimpleForm>
      <TextInput label="Breed" source="breed" />
      <NumberInput label="Min Weight, kg" source="minWeight" />
      <NumberInput label="Max Weight, kg" source="maxWeight" />
      <DailyStepGoalsInput />
    </SimpleForm>
  </Edit>
)

export default BreedEdit
