import React from 'react'
import {
  Datagrid,
  List,
  TextField,
  EditButton,
  NumberField,
  Filter,
  SelectInput
} from 'react-admin'
import { ActionsToolbar } from '../components/ActionsToolbar'

const PostFilter = props => (
  <Filter {...props}>
    <SelectInput
      source="type"
      choices={[
        { id: 'cat', name: 'Cats' },
        { id: 'dog', name: 'Dogs' },
      ]}
      alwaysOn
    />
  </Filter>
)

const BreedList = props => (
  <List
    {...props}
    bulkActionButtons={false}
    actions={<ActionsToolbar />}
    filters={<PostFilter />}
    title="List of Breeds"
    filterDefaultValues={{ type: 'dog' }}
    sort={{ field: 'breed', order: 'ASC' }}
  >
    <Datagrid>
      <TextField label="Breed" source="breed" fieldKey="breed" />
      <NumberField label="Min Weight, kg" source="minWeight" />
      <NumberField label="Max Weight, kg" source="maxWeight" />
      <NumberField label="Daily Step Goals" source="dailyStepGoals" />
      <EditButton />
    </Datagrid>
  </List>
)

export default BreedList
