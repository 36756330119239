export const convertPetsDate = (date) => {
  if (!date?.split) return date

  const splittedStr = date.split('/')

  if (splittedStr.length !== 3) return date

  return new Date(
    parseInt(splittedStr[2], 10),
    parseInt(splittedStr[1], 10) - 1,
    parseInt(splittedStr[0], 10)
  )
}
