import BreedIcon from '@material-ui/icons/DeviceHub'

import BreedList from './BreedList'
import BreedEdit from './BreedEdit'
import BreedCreate from './BreedCreate'

export default {
    list: BreedList,
    create: BreedCreate,
    edit: BreedEdit,
    icon: BreedIcon,
}
