import React, { useState, useEffect } from 'react'
import {
  Edit,
  SimpleForm,
  TextField,
  TextInput,
  RadioButtonGroupInput,
  //DateInput,
  ImageField,
  AutocompleteInput,
  ReferenceField,
  ReferenceInput,
  FormDataConsumer,
  useShowController,
} from 'react-admin'

import { DateInput } from '@shinabr2/react-admin-date-inputs'

import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import { Typography } from '@material-ui/core'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import { makeStyles, Theme } from '@material-ui/core/styles'
import { Styles } from '@material-ui/styles/withStyles'

import * as firebase from 'firebase/app'
import 'firebase/firestore'
import moment from 'moment'

import { SaveToolbar } from '../components/SaveToolbar'
import { convertPetsDate } from '../utils/index'

export const styles: Styles<Theme, any> = {
  root: { display: 'flex' },
  spacer: { height: 20 },
  wide: { width: '34rem' },
  wide_wrapper: { width: '34rem' },
  firstCol: { display: 'inline-block' },
  secondCol: { display: 'inline-block', marginLeft: 32 },
  underline: {
    width: '28px',
    height: '2px',
    display: 'block',
    background: '#fff',
    marginTop: '4px',
  },
  totalPointsContainer: {
    flex: 1,
    borderTop: 2,
    borderTopWidth: 2,
    borderTopStyle: 'solid',
    borderTopColor: 'gray',
    textAlign: 'right',
    paddingRight: 16,
    paddingTop: 24,
    paddingBottom: 16,
    fontSize: 17,
    textDecoration: 'underline',
  },
}

const useStyles = makeStyles(styles)

const RenderWeighings = props => {
  const classes = useStyles()
  const {
    loaded, // boolean that is false until the record is available
    loading, // boolean that is true on mount, and false once the record was fetched
    record, // record fetched via dataProvider.getOne() based on the id from the location
    resource, // the resource name, deduced from the location. e.g. 'posts'
    version, // integer used by the refresh feature
  } = useShowController(props)

  const [data, setData] = useState({
    weighings: [],
    history: [],
  })

  useEffect(() => {
    ; (async () => {
      if (!loading) {
        const userRef = firebase.firestore().collection('pets').doc(record?.id)
        const snapshot = await userRef.collection('history').get()

        const historyItems = snapshot.docs.map(doc => doc.data())
        const currentYear = new Date().getFullYear()
        const getFullDate = item => {
          try {
            return new Date(`${item.activated_at}, ${currentYear}`)
          } catch (e) {
            console.log('Something wrong with event date format: ', e)
            return ''
          }
        }

        const filterCallback = (...types) => item => types.includes(item.type)
        const sortCallback = (i1, i2) =>
          getFullDate(i1) > getFullDate(i2) ? -1 : 1

        setData({
          weighings: historyItems
            .filter(filterCallback('weight'))
            .sort(sortCallback),
          badges: historyItems
            .filter(filterCallback('badges'))
            .sort(sortCallback),
          points: historyItems
            .filter(filterCallback('points', 'weight'))
            .sort(sortCallback),
        })
      }
    })()
  }, [loading]) // eslint-disable-line

  if (loading) {
    return null
  }

  const totalPoints = data.points?.reduce(
    (acc, el) => acc + el.loyalty?.value,
    0,
  )

  return (
    <>
      <Typography variant="h6" gutterBottom>
        Points history
      </Typography>
      <hr />
      {data.points?.length ? (
        <>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Score</TableCell>
                <TableCell>Title</TableCell>
                <TableCell>Date</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              { data.points.map((item, idx) => {
                return (
                  <TableRow key={ `p${idx}` }>
                    <TableCell>{ item.loyalty?.value }</TableCell>
                    <TableCell>{ item.event_title }</TableCell>
                    <TableCell>{ item.activated_at }</TableCell>
                  </TableRow>
                )
              }) }
            </TableBody>
          </Table>

          {totalPoints > 0 && (
            <div style={ styles.totalPointsContainer }>
              Total points: {totalPoints }
            </div>
          ) }
        </>
      ) : (
        <div style={ { flex: 1, padding: '0 10px', textAlign: 'center' } }>
          No data
        </div>
      ) }
      <Typography variant="h6" gutterBottom>
        Badges history
      </Typography>
      <hr />
      {data.badges?.length ? (
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Icon</TableCell>
              <TableCell>Title</TableCell>
              <TableCell>Date</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            { data.badges.map((item, idx) => {
              return (
                <TableRow key={ `b${idx}` }>
                  <TableCell>
                    <img
                      src={ item.image }
                      style={ { width: 'auto', height: '40px' } }
                    />
                  </TableCell>
                  <TableCell>{ item.event_title }</TableCell>
                  <TableCell>{ item.activated_at }</TableCell>
                </TableRow>
              )
            }) }
          </TableBody>
        </Table>
      ) : (
        <div style={ { flex: 1, padding: '0 10px', textAlign: 'center' } }>
          No data
        </div>
      ) }
      <Typography variant="h6" gutterBottom>
        Weight history
      </Typography>
      <hr />
      {data.weighings?.length ? (
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Weight</TableCell>
              <TableCell>Date</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            { data.weighings.map((item, idx) => {
              return (
                <TableRow key={ `w${idx}` }>
                  <TableCell>{ item.weight }</TableCell>
                  <TableCell>{ item.lastUpdate }</TableCell>
                </TableRow>
              )
            }) }
          </TableBody>
        </Table>
      ) : (
        <div style={ { flex: 1, padding: '0 10px', textAlign: 'center' } }>
          No data
        </div>
      ) }
    </>
  )
}

const PetEdit = props => {
  const classes = useStyles()
  const convertDate = petBirthday => {
    const splitedPetBD = petBirthday.split('/');
    if (splitedPetBD[1] < 12) {
      return splitedPetBD.reverse().join('-')
    } else {
      return moment(petBirthday).format('YYYY-MM-DD')
    }
  }

  const formatPetsDate = (date) => 
    moment(convertPetsDate(date)).format('YYYY-MM-DD HH:mm:ss')

  const parseDateBeforeSaving = (date) => 
    moment(date).format('DD/MM/YYYY')

  return (
    <div style={ { display: 'flex' } }>
      <div style={ { flex: 1, marginRight: '0.5em' } }>
        <Edit { ...props } title="Edit Pet">
          <SimpleForm toolbar={ <SaveToolbar /> }>
            <TextInput label="Name" source="name" />
            <ImageField label="Photo" source="image.src" />
            <ReferenceField label="Owner" source="owner" reference="users">
              <TextField source="firstName" />
            </ReferenceField>
            <RadioButtonGroupInput
              label="Animal"
              source="animal"
              choices={ [
                { id: 'dog', name: 'Dog' },
                { id: 'cat', name: 'Cat' },
              ] }
            />
            <FormDataConsumer>
              { ({ formData, ...rest }) =>
                formData.animal && (
                  <ReferenceInput
                    source="breed_id"
                    label="resources.labels.breedType"
                    reference="breeds"
                    filter={ { type: formData.animal } }
                    filterToQuery={ searchText => ({ breed: searchText }) }
                  >
                    <AutocompleteInput label="Breed" optionText="breed" />
                  </ReferenceInput>
                )
              }
            </FormDataConsumer>
            <RadioButtonGroupInput
              label="Gender"
              source="gender"
              choices={ [
                { id: 'male', name: 'Male' },
                { id: 'female', name: 'Female' },
              ] }
            />
            <DateInput
              label="Birthday"
              source="birthday"
              //providerOptions={{ locale: enLocale }}
              options={ { format: 'dd-MM-yyyy', } }
              format={formatPetsDate}
              parse={parseDateBeforeSaving}
            />
          </SimpleForm>
        </Edit>
      </div>
      <Card style={ { flex: 3, marginRight: '0.5em', marginTop: '1em' } }>
        <CardContent>
          <RenderWeighings { ...props } />
        </CardContent>
      </Card>
    </div>
  )
}

export default PetEdit
