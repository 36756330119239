import React from 'react'
import {
  Datagrid,
  List,
  TextField,
  ImageField,
  FileField,
  ReferenceField,
  useDataProvider,
  useNotify,
  useRedirect,
  Button,
  EditButton,
} from 'react-admin'

// const ApproveButton = ({ record }) => {
//   const notify = useNotify()
//   const redirect = useRedirect()
//   const dataProvider = useDataProvider()

//   const approve = () => {
//     dataProvider
//       .update('queue', {
//         id: record.id,
//         data: { ...record, approved: true, received: new Date() },
//       })
//       // .then(() => {
//       //   dataProvider
//       //     .getOne('pets', { id: record.pet_id })
//       //     .then(({ data }) => {
//       //       dataProvider.update('pets', {
//       //         id: record.pet_id,
//       //         data: {
//       //           loyalty: {
//       //             ...data.loyalty,
//       //             badges: +data.loyalty.badges + 1,
//       //           },
//       //         },
//       //       })
//       //     })
//       //     .catch(error => {})
//       // })
//       // .then(() => {
//       //   dataProvider
//       //     .getOne('users', { id: record.owner_id })
//       //     .then(({ data }) => {
//       //       dataProvider.update('users', {
//       //         id: record.owner_id,
//       //         data: {
//       //           loyalty: {
//       //             ...data.loyalty,
//       //             badges: +data.loyalty.badges + 1,
//       //           },
//       //         },
//       //       })
//       //     })
//       //     .catch(error => {})
//       // })
//       .then(response => {
//         notify('Submission approved')
//       })
//       .catch(error => {
//         // failure side effects go here
//         notify(`Submission approval error: ${error.message}`, 'warning')
//       })
//   }
//
//   return record && record.approved === null ? (
//     <Button label="Approve" onClick={approve} />
//   ) : null
// }

// TODO: add Reject button

const HistoryList = props => {
  let filter = {
    collectionQuery: collection =>
      collection
        .where('type', 'in', ['badges', 'quests'])
  }
  return (
    <List
      {...props}
      filter={filter}
      actions={null}
      bulkActionButtons={false}
      title="History"
    >
      <Datagrid>
        <ReferenceField label="User" source="owner_id" reference="users">
          <TextField source="firstName" />
        </ReferenceField>
        <ReferenceField label="Badge" source="offer_id" reference="tools">
          <TextField source="title" />
        </ReferenceField>
        <ReferenceField label="Pet" source="pet_id" reference="pets">
          <TextField source="name" />
        </ReferenceField>
        <TextField label="Status" source="status" />
        <EditButton />
      </Datagrid>
    </List>
  )
}

// <TextField label="Approved" source="approved" />
// <ApproveButton />

export default HistoryList
