import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Form, Field } from 'react-final-form'
// import { useLocation } from 'react-router-dom'
import * as firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/firestore'

import Avatar from '@material-ui/core/Avatar'
import Button from '@material-ui/core/Button'
import Card from '@material-ui/core/Card'
import CardActions from '@material-ui/core/CardActions'
import CircularProgress from '@material-ui/core/CircularProgress'
import TextField from '@material-ui/core/TextField'
import { createMuiTheme, makeStyles } from '@material-ui/core/styles'
import { ThemeProvider } from '@material-ui/styles'
import LockIcon from '@material-ui/icons/Lock'

import { Notification } from 'react-admin'
import { useLogin, useNotify } from 'ra-core'
import { lightTheme } from './themes'

const useStyles = makeStyles(theme => ({
    main: {
        display: 'flex',
        flexDirection: 'column',
        minHeight: '100vh',
        alignItems: 'center',
        justifyContent: 'flex-start',
        background: '#000',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
    },
    card: {
        minWidth: 300,
        marginTop: '6em',
    },
    avatar: {
        margin: '1em',
        display: 'flex',
        justifyContent: 'center',
    },
    icon: {
        backgroundColor: theme.palette.secondary.main,
    },
    hint: {
        marginTop: '1em',
        display: 'flex',
        justifyContent: 'center',
        color: theme.palette.grey[500],
    },
    form: {
        padding: '0 1em 1em 1em',
    },
    input: {
        marginTop: '1em',
    },
    actions: {
        padding: '0 1em 1em 1em',
    },
}))

const renderInput = ({
    meta: { touched, error } = { touched: false, error: undefined },
    input: { ...inputProps },
    ...props
}) => (
    <TextField
        error={!!(touched && error)}
        helperText={touched && error}
        {...inputProps}
        {...props}
        fullWidth
    />
)

// const { Form } = withTypes<FormValues>()

const Login = () => {
    const [loading, setLoading] = useState(false)
    // const translate = useTranslate()
    const classes = useStyles()
    const notify = useNotify()
    // const [email, setEmail] = useState('')
    // const [password, setPassword] = useState('')
    const login = useLogin()
    // const location = useLocation()

    const handleSubmit = async(auth: FormValues) => {
        setLoading(true)
        console.log(auth)
        try {
          const { user } = await firebase
            .auth()
            .signInWithEmailAndPassword(auth.email, auth.password)
          if (user) {
            const preUserData = await firebase
              .firestore()
              .collection('users')
              .doc(user.uid)
              .get()
            const { isAdmin } = preUserData.data()
            if (isAdmin) {
              return login(auth)
            }
            return notify('This user does not have admin rights')
          }
          return notify('User does not exist')
        } catch (err) {
          return notify(`${err}`)
        }
        // login(auth, location.state ? location.state.nextPathname : '/').catch(
        //     (error: Error) => {
        //         setLoading(false)
        //         notify(
        //             typeof error === 'string'
        //                 ? error
        //                 : typeof error === 'undefined' || !error.message
        //                 ? 'ra.auth.sign_in_error'
        //                 : error.message,
        //             'warning'
        //         )
        //     }
        // )
    }

    const validate = (values: FormValues) => {
        const errors: FormValues = {}
        if (!values.email) {
            errors.email = 'Please provide correct email'
        }
        if (!values.password) {
            errors.password = 'Please provide correct password'
        }
        return errors
    }

    return (
        <Form
            onSubmit={handleSubmit}
            validate={validate}
            render={({ handleSubmit }) => (
                <form onSubmit={handleSubmit} noValidate>
                    <div className={classes.main}>
                        <Card className={classes.card}>
                            <div className={classes.avatar}>
                                <Avatar className={classes.icon}>
                                    <LockIcon />
                                </Avatar>
                            </div>
                            <div className={classes.form}>
                                <div className={classes.input}>
                                    <Field
                                        autoFocus
                                        name="email"
                                        component={renderInput}
                                        label="Email"
                                        type="email"
                                        disabled={loading}
                                    />
                                </div>
                                <div className={classes.input}>
                                    <Field
                                        name="password"
                                        component={renderInput}
                                        label="Password"
                                        type="password"
                                        disabled={loading}
                                    />
                                </div>
                            </div>
                            <CardActions className={classes.actions}>
                                <Button
                                    variant="contained"
                                    type="submit"
                                    color="primary"
                                    disabled={loading}
                                    fullWidth
                                >
                                    {loading && (
                                        <CircularProgress
                                            size={25}
                                            thickness={2}
                                        />
                                    )}
                                    Login
                                </Button>
                            </CardActions>
                        </Card>
                        <Notification />
                    </div>
                </form>
            )}
        />
    )
}

Login.propTypes = {
    authProvider: PropTypes.func,
    previousRoute: PropTypes.string,
}

// We need to put the ThemeProvider decoration in another component
// Because otherwise the useStyles() hook used in Login won't get
// the right theme
const LoginWithTheme = (props: any) => (
    <ThemeProvider theme={createMuiTheme(lightTheme)}>
        <Login {...props} />
    </ThemeProvider>
)

export default LoginWithTheme
