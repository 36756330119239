import React, { Fragment } from 'react'
import {
  TextField,
  TextInput,
  Edit,
  SimpleForm,
  DateField,
  NumberField,
  EmailField,
  FileField,
  BooleanInput,
  ReferenceField,
  RadioButtonGroupInput,
  FormDataConsumer,
  required
} from 'react-admin'

import { Typography, Box } from '@material-ui/core'
import { makeStyles, Theme } from '@material-ui/core/styles'
import { Styles } from '@material-ui/styles/withStyles'
import { SaveToolbar } from '../components/SaveToolbar'

export const styles: Styles<Theme, any> = {
  wide: { width: '34rem' },
  wide_wrapper: { width: '34rem' },
  firstCol: { display: 'inline-block' },
  secondCol: { display: 'inline-block', marginLeft: 32 },
  underline: {
    width: '28px',
    height: '2px',
    display: 'block',
    background: '#fff',
    marginTop: '4px',
  },
}

const useStyles = makeStyles(styles)

const statusChoices = [
  { id: 'activated', name: 'Activated' },
  { id: 'rejected', name: 'Rejected' }
]

const StatusEdit = props => {
  console.log('props', props)
  const { record, newStatus } = props
  console.log('record', record)
  const classes = useStyles()
  if (record.status === 'pending' && newStatus !== 'rejected') {
    return (
      <RadioButtonGroupInput
        label="Select status"
        source="status"
        formClassName={classes.wide}
        fullWidth
        choices={statusChoices}
      />
    )
  }
  if (record.status === 'pending' && newStatus === 'rejected') {
    return (
      <Fragment>
        <RadioButtonGroupInput
          label="Select status"
          source="status"
          formClassName={classes.wide}
          fullWidth
          choices={statusChoices}
        />
        <TextInput
          helperText="Comment is required if user upload is rejected."
          source="comment"
          validate={required()}
        />
      </Fragment>
    )
  }
  if (record.status === 'activated') {
    return (
      <Fragment>
        <RadioButtonGroupInput
          label="Select status"
          source="status"
          formClassName={classes.wide}
          fullWidth
          choices={statusChoices}
          disabled
        />
      </Fragment>
    )
  }
  if (record.status === 'rejected') {
    return (
      <Fragment>
        <RadioButtonGroupInput
          label="Select status"
          source="status"
          formClassName={classes.wide}
          fullWidth
          choices={statusChoices}
          disabled
        />
        <TextInput
          helperText="Comment is required if user upload is rejected."
          source="comment"
          disabled
        />
      </Fragment>
    )
  }
}

const HistoryEdit = props => {
  const classes = useStyles()
  return (
    <Edit {...props} title="Edit History" mutationMode="pessimistic">
        <SimpleForm toolbar={<SaveToolbar />} redirect={'/'}>
          <ReferenceField source="offer_id" reference="tools">
            <TextField source="title" />
          </ReferenceField>
          <TextField label="Offer type" source="type" />
          <NumberField source="loyalty.value" />
          <TextField source="loyalty.type" />
          <ReferenceField label="Owner" source="owner_id" reference="users">
            <TextField source="firstName" />
          </ReferenceField>
          <ReferenceField label="Pet" source="pet_id" reference="pets">
            <TextField source="name" />
          </ReferenceField>
          <FileField label="Attachment" source="image.src" title="Submitted image/pdf" />
          <FormDataConsumer subscription={{ values: true }}>
            {({ formData, ...rest }) => <StatusEdit newStatus={formData.status} {...rest} /> }
          </FormDataConsumer>
          <DateField label="Submitted" source="submitted" />
          <DateField label="Last update" source="lastupdate" />
        </SimpleForm>
    </Edit>
  )
}

const requiredValidate = [required()]

export default HistoryEdit
