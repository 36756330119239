import * as React from 'react'
import { FC } from 'react'
import {
    List,
    ListItem,
    ListItemAvatar,
    ListItemText,
    Avatar,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import CommentIcon from '@material-ui/icons/Comment'
import { Link } from 'react-router-dom'
import { useTranslate } from 'react-admin'

import CardWithIcon from './CardWithIcon'

const useStyles = makeStyles(theme => ({
    avatar: {
        background: theme.palette.background.paper,
    },
    listItemText: {
        overflowY: 'hidden',
        height: '4em',
        display: '-webkit-box',
        WebkitLineClamp: 2,
        WebkitBoxOrient: 'vertical',
    },
}))

const PendingBadges = ({ badges = [], nb }) => {
    const classes = useStyles()
    const translate = useTranslate()
    return (
        <CardWithIcon
            to="/queue"
            icon={CommentIcon}
            title={translate('pos.dashboard.pending_badges')}
            subtitle={nb}
        >
            <List>
                {badges.map((record) => (
                    <ListItem
                        key={record.id}
                        button
                        component={Link}
                        to={`/queue/${record.id}`}
                        alignItems="flex-start"
                    >
                        <ListItemText
                            secondary={record.title}
                            className={classes.listItemText}
                            style={{ paddingRight: 0 }}
                        />
                    </ListItem>
                ))}
            </List>
        </CardWithIcon>
    )
}

export default PendingBadges
