import React from 'react'
import {
  Create,
  SimpleForm,
  TextInput,
  NumberInput,
  SelectInput,
  FormDataConsumer
} from 'react-admin'

const DailyStepGoalsInput = ({ record = {}, ...rest }) =>
  record && record.type === 'dog' ? (
    <NumberInput
      label="Daily Step Goals"
      source="dailyStepGoals"
      record={record}
      {...rest}
    />
  ) : null

const breedTypeChoices = [
  { id: 'dog', name: 'Dog' },
  { id: 'cat', name: 'Cat' }
]

const BreedCreate = props => (
  <Create {...props} title="Create Breed">
    <SimpleForm>
      <TextInput label="Breed" source="breed" />
      <SelectInput
        label="resources.labels.breedType"
        source="type"
        choices={breedTypeChoices}
        defaultValue={'dog'}
      />
      <FormDataConsumer>
        {({ formData, ...rest }) =>
          formData.type === 'dog' && (
            <NumberInput
              label="Daily step goals"
              source="dailyStepGoals" />
          )
        }
      </FormDataConsumer>
      <NumberInput label="Min Weight, kg" source="minWeight" />
      <NumberInput label="Max Weight, kg" source="maxWeight" />
      <DailyStepGoalsInput />
    </SimpleForm>
  </Create>
)

export default BreedCreate
