import React from 'react'
import moment from 'moment'

import { convertPetsDate } from '../utils/index'

const CustomDateField = ({ record, displayFormat }) => {
  const { birthday } = record

  return <span>{moment(convertPetsDate(birthday)).format(displayFormat)}</span>
}

export default CustomDateField
