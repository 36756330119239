import React from 'react'
import { Admin, Resource } from 'react-admin'
import polyglotI18nProvider from 'ra-i18n-polyglot'
import {
  FirebaseAuthProvider,
  FirebaseDataProvider,
} from 'react-admin-firebase'
import firebase from 'firebase'

import { Login, Layout } from './layout'
import { Dashboard } from './dashboard'

import englishMessages from './i18n/en'

import brands from './brands'
import tools from './tools'
import offers from './offers'
import users from './users'
import pets from './pets'
import breeds from './breeds'
import history from './history'

const i18nProvider = polyglotI18nProvider(locale => {
    // if (locale === 'fr') {
    //     return import('./i18n/fr').then(messages => messages.default)
    // }

    // Always fallback on english
    return englishMessages
}, 'en')

const firebaseConfig = {
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_DATABASE_URL,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_APP_ID,
  measurementId: process.env.REACT_APP_MEASUREMENT_ID,
}

const firebaseApp = firebase.initializeApp(firebaseConfig)

const options = {
  // logging: true,
  app: firebaseApp,
  // watch: ['posts'];
  // dontwatch: ['comments'];
  persistence: 'session',
  // Disable the metadata; 'createdate', 'lastupdate', 'createdby', 'updatedby'
  // disableMeta: true
  // Changes meta fields like 'createdby' and 'updatedby' to store user IDs instead of email addresses
  associateUsersById: false,
  // Adds 'deleted' meta field for non-destructive deleting functionality
  // NOTE: Hides 'deleted' records from list views unless overridden by filtering for {deleted: true}
  softDelete: false,
  // Prevents document from getting the ID field added as a property
  dontAddIdFieldToDoc: true
}

const dataProvider = FirebaseDataProvider(firebaseConfig, options)
const authProvider = FirebaseAuthProvider(firebaseConfig, options)

const App = () => {

  if (!dataProvider) {
      return (
          <div className="loader-container">
              <div className="loader">Loading...</div>
          </div>
      )
  }

  return (
    <Admin
      dataProvider={dataProvider}
      authProvider={authProvider}
      dashboard={Dashboard}
      i18nProvider={i18nProvider}
      loginPage={Login}
      layout={Layout}
    >
      <Resource name="brands" {...brands} />
      <Resource name="tools" {...tools} />
      <Resource name="offers" {...offers} />
      <Resource name="users" {...users} />
      <Resource name="pets" {...pets} />
      <Resource name="breeds" {...breeds} />
      <Resource name="queue" {...history} />
    </Admin>
  )
}

export default App
