import OfferIcon from '@material-ui/icons/Redeem'
import { Admin, Resource, EditGuesser, ListGuesser, ShowGuesser } from 'react-admin'
import OfferList from './OfferList'
import OfferCreate from './OfferCreate'
import OfferEdit from './OfferEdit'
// import OfferShow from './OfferShow'

export default {
    list: OfferList,
    create: OfferCreate,
    edit: OfferEdit,
    show: ShowGuesser,
    icon: OfferIcon,
}
