import React from 'react'
import {
  List,
  EditButton
} from 'react-admin'
import {
    Grid,
    Card,
    CardMedia,
    CardContent,
    CardActions,
    Typography,
    Button,
} from '@material-ui/core'
import { Link } from 'react-router-dom'

import { useTranslate } from 'react-admin'
import { stringify } from 'query-string'
import brands from './index'
import { makeStyles } from '@material-ui/core/styles'
import { ActionsToolbar } from '../components/ActionsToolbar'

const useStyles = makeStyles({
    root: {
        marginTop: '1em',
    },
    media: {
        height: 100,
        backgroundSize: 'contain',
        margin: '1em'
    },
    title: {
        paddingBottom: '0.5em',
    },
    description: {
      display: '-webkit-box',
      '-webkit-line-clamp': 1,
      '-webkit-box-orient': 'vertical',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      padding: '0 16px'
    },
    actionSpacer: {
        display: 'flex',
        justifyContent: 'space-around',
    },
})

const LinkToRelatedOffers = ({ record }) => {
    const translate = useTranslate()
    const classes = useStyles()
    return record ? (
        <Button
            size="small"
            color="primary"
            component={Link}
            to={{
                pathname: '/offers',
                search: stringify({
                    page: 1,
                    perPage: 20,
                    sort: 'reference',
                    order: 'ASC',
                    filter: JSON.stringify({ brand_id: record.id }),
                }),
            }}
            className={classes.link}
        >
            <brands.icon className={classes.icon} />
            {translate('resources.categories.fields.offers')}
        </Button>
    ) : null
}


const BrandsGrid = props => {
    const classes = useStyles(props)
    const { data, ids } = props
    return ids ? (
        <Grid container spacing={2} className={classes.root}>
            {ids.map(id => (
                <Grid key={id} xs={12} sm={6} md={4} lg={3} xl={2} item>
                    <Card>
                        <CardMedia
                            image={ data[id].image && data[id].image.src ?
                              data[id].image.src :
                              'https://via.placeholder.com/120x120'
                            }
                            className={classes.media}
                        />
                        <CardContent className={classes.title}>
                            <Typography
                                variant="h5"
                                component="h2"
                                align="center"
                            >{data[id].title}</Typography>
                        </CardContent>
                        <CardContent className={classes.description}>
                            <Typography
                                variant="caption"
                                align="center"
                            >{data[id].description}</Typography>
                        </CardContent>
                        <CardActions
                            classes={{ spacing: classes.actionSpacer }}
                        >
                            <LinkToRelatedOffers record={data[id]} />
                            <EditButton
                                basePath="/brands"
                                record={data[id]}
                            />
                        </CardActions>
                    </Card>
                </Grid>
            ))}
        </Grid>
    ) : null
}

const BrandList = props => (
  <List
    {...props}
    sort={{ field: 'name', order: 'ASC' }}
    perPage={20}
    actions={<ActionsToolbar />}
    component="div"
    bulkActionButtons={false}
    title="Brands"
  >
    <BrandsGrid />
  </List>
)

export default BrandList
