import React from 'react'
import {
  Datagrid,
  List,
  ReferenceField,
  BooleanField,
  TextField,
  FunctionField,
  EditButton,
  DateField,
} from 'react-admin'

import { ActionsToolbar } from '../components/ActionsToolbar'

const OfferList = props => (
  <List
    {...props}
    actions={<ActionsToolbar />}
    bulkActionButtons={false}
    title="Offers"
  >
    <Datagrid>
      <TextField label="Title" source="title" />
      <TextField label="Type" source="type" />
      <BooleanField label="Fidel" source="isFidel" />
      <ReferenceField source="brand_id" reference="brands">
        <TextField source="title" />
      </ReferenceField>
      <TextField label="Category" source="category" />
      <TextField label="Eligibility Setup" source="eligibility" />
      <DateField label="Start date" source="startDate" />
      <DateField label="End date" source="endDate" />
      <BooleanField label="Active" source="isActive" />
      <EditButton label="Edit" />
    </Datagrid>
  </List>
)

export default OfferList
