import React from 'react'
import {
  TextInput,
  ImageField,
  ImageInput,
  BooleanInput,
  Edit,
  SimpleForm,
} from 'react-admin'

import { SaveToolbar } from '../components/SaveToolbar'

const BrandEdit = props => (
  <Edit {...props} title="Edit Brand">
    <SimpleForm toolbar={<SaveToolbar />}>
      <TextInput label="Title" source="title" />
      <TextInput
        label="Description"
        source="description"
        multiline
        fullWidth
      />
      <ImageInput source="image" label="Logo" accept="image/*" multiple={false}>
        <ImageField source="src" />
      </ImageInput>
      <BooleanInput
        label="resources.labels.isFidel"
        source="isFidel"
        disabled
      />
    </SimpleForm>
  </Edit>
)

export default BrandEdit
