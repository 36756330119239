import React from 'react'
import {
  Datagrid,
  List,
  TextField,
  EditButton,
  DateField,
  EmailField,
  ReferenceField,
} from 'react-admin'

import { ActionsToolbar } from '../components/ActionsToolbar'

const UserList = props => (
  <List
    {...props}
    actions={<ActionsToolbar />}
    bulkActionButtons={false}
    title="List of users"
  >
    <Datagrid>
      <TextField label="First Name" source="firstName" />
      <TextField label="Last Name" source="lastName" />
      <TextField label="Mobile Number" source="mobileNumber" />
      <TextField label="Address" source="address.addressLine" />
      <EmailField label="Email" source="email" />
      <ReferenceField
        label="Referred From"
        source="referredBy"
        reference="users"
      >
        <TextField source="firstName" />
      </ReferenceField>
      <TextField label="Referral Code" source="usersReferralCode" />
      <TextField label="Subscription Type" source="subscriptionType" />
      <DateField label="Registration Date" source="registrationDate" />
      <EditButton label="Edit" />
    </Datagrid>
  </List>
)

export default UserList
