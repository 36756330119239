import React from 'react'
import {
  Datagrid,
  List,
  ReferenceField,
  TextField,
  FunctionField,
  NumberField,
  BooleanField,
  DateField,
  EditButton,
} from 'react-admin'

import { ActionsToolbar } from '../components/ActionsToolbar'

const ToolList = props => (
  <List
    {...props}
    actions={<ActionsToolbar />}
    bulkActionButtons={false}
    title="Tools"
  >
    <Datagrid>
      <TextField source="title" />
      <TextField source="type" />
      <TextField source="eligibility" />
      <BooleanField source="isActive" />
      <EditButton label="Edit" />
    </Datagrid>
  </List>
)

export default ToolList
