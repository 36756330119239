import React from 'react'
// import { useSelector } from 'react-redux'
import { Layout, Sidebar } from 'react-admin'
import AppBar from './AppBar'
import Menu from './Menu'
import { darkTheme } from './themes'

const CustomSidebar = (props: any) => <Sidebar {...props} size={200} />

export default (props) => {
    return (
        <Layout
            {...props}
            appBar={AppBar}
            sidebar={CustomSidebar}
            menu={Menu}
            theme={darkTheme}
        />
    )
}
