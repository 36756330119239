import React, { Fragment } from 'react'
import {
  TextInput,
  NumberInput,
  SelectInput,
  ImageField,
  ImageInput,
  Edit,
  SimpleForm,
  ReferenceInput,
  AutocompleteInput,
  DateInput,
  RadioButtonGroupInput,
  BooleanInput,
  BooleanField,
  TabbedForm,
  FormTab,
  FormDataConsumer,
  useTranslate,
  required,
  number,
  minValue
} from 'react-admin'
import { Typography, Box } from '@material-ui/core'
import { makeStyles, Theme } from '@material-ui/core/styles'
import { Styles } from '@material-ui/styles/withStyles'
import { SaveToolbar } from '../components/SaveToolbar'

export const styles: Styles<Theme, any> = {
  wide: { width: '34rem', marginRight: '10px' },
  wide_wrapper: { width: '34rem' },
  firstCol: { display: 'inline-block' },
  secondCol: { display: 'inline-block', marginLeft: 32 },
  underline: {
    width: '28px',
    height: '2px',
    display: 'block',
    background: '#fff',
    marginTop: '4px',
  },
}

const useStyles = makeStyles(styles)

const requiredValidate = [required()]

const SectionTitle = ({ label }: { label: string }) => {
    const translate = useTranslate()

    return (
        <Typography variant="h6" gutterBottom>
            {translate(label)}
            <span style={{
                width: '3rem',
                height: '0.125rem',
                display: 'block',
                background: '#f50057',
                marginTop: '0.25rem'
              }}>
            </span>
        </Typography>
    )
}

const Separator = () => <Box pt="1em" />

const typeChoices = [
  { id: 'partners', name: 'Health partners' },
  { id: 'rewards', name: 'Rewards' }
]

const partnersCategoryChoices = [
  { id: 'exercise', name: 'Exercise' },
  { id: 'nutrition', name: 'Nutrition' },
  { id: 'wellbeing', name: 'Wellbeing' },
  { id: 'behaviour', name: 'Behaviour' },
]

const rewardsCategoryChoices = [
  { id: 'gift_card', name: 'Gift card' },
  { id: 'reward_partner', name: 'Reward Partner' }
]

const loyaltyRewardChoices = [
  { id: 'badges', name: 'Badges' },
  { id: 'points', name: 'Points' }
]

// const rewardsCategoryChoices = [
//   { id: 'gifts', name: 'Gift card' },
//   { id: 'partners', name: 'Reward Partner' },
// ]

// const offerFlowChoices = [
//   { id: 'none', name: 'None' },
//   { id: 'discount', name: 'Discount' },
//   { id: 'utm', name: 'Utm' },
//   { id: 'deepLink', name: 'Deep link' }
// ]

const eligibilityChoices = [
  { id: 'both', name: 'Both' },
  { id: 'dog', name: 'Dog' },
  { id: 'cat', name: 'Cat' },
]

const cooldownChoices = [
  { id: 'month', name: '30 days' },
  { id: 'week', name: '7 days' },
  { id: 'day', name: '1 day' },
]

const validateReward = [required(), number(), minValue(1)]

const OfferEdit = props => {
  const classes = useStyles()
  return (
    <Edit {...props} title="Edit Offer">
      <SimpleForm>
        <SectionTitle label="resources.offers.fieldGroups.overview" />
        <TextInput
          autoFocus
          source="title"
          formClassName={classes.wide}
          fullWidth
          validate={required()}
        />
        <TextInput
          source="description"
          formClassName={classes.wide}
          rowsMax={5}
          multiline
          fullWidth
          validate={required()}
        />
        <TextInput
          label="Url"
          helperText="resources.labels.helperUrl"
          formClassName={classes.wide}
          fullWidth
          source="url"
          validate={required()}
        />
        <ImageInput
          source="image"
          label="resources.labels.offerImage"
          accept="image/*"
          multiple={false}
        >
          <ImageField source="src" />
        </ImageInput>
        <SelectInput
          formClassName={classes.wide}
          label="Offer type"
          fullWidth
          source="type"
          defaultValue={'partners'}
          choices={typeChoices}
          disabled
        />
        <FormDataConsumer className={classes.wide}>
          {({ formData, ...rest }) =>
            formData.type === 'partners' ? (
              <Fragment>
                <BooleanInput
                  label="resources.labels.isFidel"
                  source="isFidel"
                  disabled
                />
                <ReferenceInput
                  source="brand_id"
                  reference="brands"
                  className={classes.wide}
                  validate={required()}
                  filter={{ isFidel: formData.isFidel }}
                  filterToQuery={searchText => ({ title: searchText })}>
                    <AutocompleteInput optionText="title" />
                </ReferenceInput>
                <RadioButtonGroupInput
                  label="Category"
                  source="category"
                  formClassName={classes.wide}
                  fullWidth
                  validate={required()}
                  choices={partnersCategoryChoices}
                  {...rest}
                />
              </Fragment>
            ) : (
              <Fragment>
                <ReferenceInput
                  source="brand_id"
                  reference="brands"
                  className={classes.wide}
                  validate={required()}
                  filter={{ isFidel: formData.isFidel }}
                  filterToQuery={searchText => ({ title: searchText })}>
                    <AutocompleteInput optionText="title" />
                </ReferenceInput>
                <RadioButtonGroupInput
                  label="Category"
                  source="category"
                  formClassName={classes.wide}
                  fullWidth
                  validate={required()}
                  choices={rewardsCategoryChoices}
                  disabled
                />
              </Fragment>
            )
          }
        </FormDataConsumer>
        <FormDataConsumer className={classes.wide}>
          {({ formData, ...rest }) => formData.category === 'reward_partner' && (
            <Fragment>
              <SectionTitle label="Discount codes" />
              Bronze<br />
              <NumberInput
                label="resources.labels.percentOff"
                source="discount.bronze.percentOff"
                formClassName={classes.firstCol}
                validate={validateReward}
              />
              <TextInput
                label="resources.labels.discountCode"
                source="discount.bronze.code"
                formClassName={classes.secondCol}
                validate={required()}
              />
              <br />Silver<br />
              <NumberInput
                label="resources.labels.percentOff"
                source="discount.silver.percentOff"
                formClassName={classes.firstCol}
                validate={validateReward}
              />
              <TextInput
                label="resources.labels.discountCode"
                source="discount.silver.code"
                formClassName={classes.secondCol}
                validate={required()}
              />
              <br />Gold<br />
              <NumberInput
                label="resources.labels.percentOff"
                source="discount.gold.percentOff"
                formClassName={classes.firstCol}
                validate={validateReward}
              />
              <TextInput
                label="resources.labels.discountCode"

                source="discount.gold.code"
                formClassName={classes.secondCol}
                validate={required()}
              />
          </Fragment>
        )}
        </FormDataConsumer>
        <br />
        <SectionTitle label="resources.offers.fieldGroups.config" />
        <BooleanInput
          label="resources.labels.isActive"
          source="isActive"
          defaultValue={true}
        />
        <NumberInput
          label="resources.labels.displayPriority"
          helperText="resources.labels.displayPriorityHelp"
          source="displayPriority"
          defaultValue={1}
          min={1}
          style={{ marginBottom: 8 }}
          validate={[number(), minValue(1)]}
        />

        <DateInput
          label="Start date"
          source="startDate"
          formClassName={classes.firstCol}
          helperText="resources.labels.startDate"
          locales="en-GB"
          validate={required()}
        />
        <DateInput
          label="End date"
          source="endDate"
          formClassName={classes.secondCol}
          helperText="resources.labels.endDate"
          locales="en-GB"
          validate={required()}
        />
        <BooleanInput
          label="resources.labels.isPremium"
          source="isPremium"
        />
        <RadioButtonGroupInput
          label="resources.labels.eligibility"
          formClassName={classes.wide}
          fullWidth
          source="eligibility"
          choices={eligibilityChoices}
          defaultValue={'both'}
        />
        <FormDataConsumer className={classes.wide}>
          {({ formData, ...rest }) =>
            formData.type === 'partners' && (
              <Fragment>
                <SectionTitle label="resources.offers.fieldGroups.loyalty_reward" />
                <NumberInput
                  label="resources.labels.rewardAmount"
                  helperText="resources.labels.pointsExpireHelp"
                  source="loyalty.value"
                  validate={validateReward}
                  defaultValue={1}
                />
                <SelectInput
                  label="Type"
                  source="loyalty.type"
                  choices={loyaltyRewardChoices}
                  defaultValue={'points'}
                  value={'points'}
                  disabled
                />
              </Fragment>
            )
          }
        </FormDataConsumer>
      </SimpleForm>
    </Edit>
  )
}

export default OfferEdit
