import React from 'react'
import {
  TextInput,
  ImageField,
  ImageInput,
  BooleanInput,
  Create,
  SimpleForm,
  SelectInput,
  Button,
  useRedirect,
  useNotify,
  FormDataConsumer,
  useDataProvider,
} from 'react-admin'
// import { makeStyles, Theme } from '@material-ui/core/styles'
// import { Styles } from '@material-ui/styles/withStyles'

// export const styles: Styles<Theme, any> = {
//   wide: { width: '34rem', marginRight: '10px' },
//   wide_wrapper: { width: '34rem' },
//   firstCol: { display: 'inline-block' },
//   secondCol: { display: 'inline-block', marginLeft: 32 },
//   underline: {
//     width: '28px',
//     height: '2px',
//     display: 'block',
//     background: '#fff',
//     marginTop: '4px',
//   },
// }

// const useStyles = makeStyles(styles)
// const classes = useStyles()


const typeChoices = [
  { id: 'health', name: 'Health' },
  { id: 'rewards', name: 'Rewards' }
]


const BrandCreate = props => (
  <Create {...props} title="Create Brand">
    <SimpleForm>
      <TextInput label="Title" source="title" />
      <TextInput label="Description" source="description" multiline fullWidth />
      <ImageInput source="image" label="Logo" accept="image/*" multiple={false}>
        <ImageField source="src" />
      </ImageInput>
      <BooleanInput
        label="resources.labels.isFidel"
        source="isFidel"
        defaultValue={false}
      />
      <SelectInput
          label="Partner type"
          fullWidth
          source="type"
          defaultValue={'partners'}
          choices={typeChoices}
        />
    </SimpleForm>
  </Create>
)

export default BrandCreate
