import * as React from 'react'
import { useState } from 'react'
import { useSelector } from 'react-redux'
// import SettingsIcon from '@material-ui/icons/Settings'
// import LabelIcon from '@material-ui/icons/Label'
// import { useMediaQuery, Theme } from '@material-ui/core'
import { useTranslate, DashboardMenuItem, MenuItemLink } from 'react-admin'

import brands from '../brands'
import tools from '../tools'
import offers from '../offers'
import users from '../users'
import pets from '../pets'
import breeds from '../breeds'
import history from '../history'
// import settings from '../settings'
// import SubMenu from './SubMenu'

// type MenuName = 'menuCatalog' | 'menuSales' | 'menuCustomers'

const Menu = ({ onMenuClick, dense, logout }) => {
    // const [state, setState] = useState({
    //     menuSettings: false,
    // })
    // const translate = useTranslate()
    //
    // const handleToggle = (menu: MenuName) => {
    //     setState(state => ({ ...state, [menu]: !state[menu] }))
    // }

    return (
        <div>
            <DashboardMenuItem onClick={onMenuClick} sidebarIsOpen={true} />
            <MenuItemLink
                to={'/brands'}
                primaryText='Brands'
                leftIcon={<brands.icon />}
                onClick={onMenuClick}
                dense={dense}
            />
            <MenuItemLink
                to={'/tools'}
                primaryText='Tools'
                leftIcon={<tools.icon />}
                onClick={onMenuClick}
                dense={dense}
            />
            <MenuItemLink
                to={'/offers'}
                primaryText='Offers'
                leftIcon={<offers.icon />}
                onClick={onMenuClick}
                dense={dense}
            />
            <MenuItemLink
                to={'/users'}
                primaryText='Users'
                leftIcon={<users.icon />}
                onClick={onMenuClick}
                dense={dense}
            />
            <MenuItemLink
                to={'/pets'}
                primaryText='Pets'
                leftIcon={<pets.icon />}
                onClick={onMenuClick}
                dense={dense}
            />
            <MenuItemLink
                to={'/breeds'}
                primaryText='Breeds'
                leftIcon={<breeds.icon />}
                onClick={onMenuClick}
                dense={dense}
            />
            <MenuItemLink
                to={'/queue'}
                primaryText='History'
                leftIcon={<history.icon />}
                onClick={onMenuClick}
                dense={dense}
            />
        </div>
    )
}

// <SubMenu
//     handleToggle={() => handleToggle('menuSales')}
//     isOpen={state.menuSales}
//     sidebarIsOpen={true}
//     name="pos.menu.sales"
//     // icon={<orders.icon />}
//     dense={dense}
// >
//     <MenuItemLink
//         to={'/offers'}
//         primaryText='Offers'
//         // leftIcon={<orders.icon />}
//         onClick={onMenuClick}
//         sidebarIsOpen={true}
//         dense={dense}
//     />
// </SubMenu>


export default Menu
