import React from 'react'
import {
  Datagrid,
  Show,
  TextField,
  ImageField,
  ShowButton,
  TabbedShowLayout,
  Tab,
  ReferenceManyField
} from 'react-admin'

const BrandShow = props => (
  <Show {...props}>
    <TabbedShowLayout>
      <Tab label="Summary">
        <TextField label="Title" source="title" />
        <TextField label="Description" source="description" />
        <ImageField source="image.src" />
      </Tab>
      <Tab label="Offers" path="offers">
        <ReferenceManyField
          addLabel={false}
          reference="offers"
          target="brand_id"
        >
          <Datagrid>
            <TextField source="title" />
            <ShowButton />
          </Datagrid>
        </ReferenceManyField>
      </Tab>
    </TabbedShowLayout>
  </Show>
)

export default BrandShow
