import React, { Fragment } from 'react'
import {
  TextInput,
  NumberInput,
  ImageField,
  ImageInput,
  SelectInput,
  Create,
  SimpleForm,
  ReferenceInput,
  AutocompleteInput,
  DateInput,
  RadioButtonGroupInput,
  BooleanInput,
  BooleanField,
  FormDataConsumer,
  useTranslate,
  useForm,
  required,
  number,
  minValue
} from 'react-admin'
import { Typography, Box } from '@material-ui/core'
import { makeStyles, Theme } from '@material-ui/core/styles'
import { Styles } from '@material-ui/styles/withStyles'

export const styles: Styles<Theme, any> = {
  wide: { width: '34rem' },
  wide_wrapper: { width: '34rem' },
  first_in_a_row: { display: 'inline-block' },
  next_in_a_row: { display: 'inline-block', marginLeft: 32 },
  underline: {
    width: '28px',
    height: '2px',
    display: 'block',
    background: '#fff',
    marginTop: '4px',
  },
}

const useStyles = makeStyles(styles)

const typeChoices = [
  { id: 'badges', name: 'Health checks (badges)' },
  { id: 'health', name: 'Track Health' },
  { id: 'quests', name: 'Quests' }
]

const categoryChoices = [
  { id: 'weighing', name: 'Weighing' },
  { id: 'activity', name: 'Activity' },
  { id: 'nutrition', name: 'Nutrition' }
]

const eligibilityChoices = [
  { id: 'both', name: 'Both' },
  { id: 'dog', name: 'Dog' },
  { id: 'cat', name: 'Cat' },
]

const loyaltyRewardChoices = [
  { id: 'badges', name: 'Badges' },
  { id: 'points', name: 'Points' }
]

const loyaltyRewardPoints = [
  { id: 'points', name: 'Points' }
]

const loyaltyRewardBadges = [
  { id: 'badges', name: 'Badges' }
]

const expirationChoices = [
  { id: 'never', name: 'Never' },
  { id: '365', name: '365 days' },
]

const cooldownChoices = [
  { id: 'month', name: '30 days' },
  { id: 'week', name: '7 days' },
  { id: 'day', name: '1 day' },
]

const validateReward = [required(), number(), minValue(1)]

const ToolCreate = props => {
  const classes = useStyles()
  return (
    <Create {...props} title="Create Tool">
      <SimpleForm>
        <SectionTitle label="resources.tools.fieldGroups.overview" />
        <TextInput
          autoFocus
          source="title"
          formClassName={classes.wide}
          fullWidth
          validate={required()}
        />
        <TextInput
          source="description"
          formClassName={classes.wide}
          rowsMax={5}
          multiline
          fullWidth
          validate={required()}
        />
        <SelectInput
          formClassName={classes.wide}
          fullWidth
          source="type"
          choices={typeChoices}
          validate={required()}
        />
        <FormDataConsumer className={classes.wide}>
          {({ formData, ...rest }) =>
            formData.type !== 'health' ? (
              <ImageInput
                source="image"
                label="resources.labels.badgeIcon"
                accept="image/*"
                multiple={false}
                validate={required()}
              >
                <ImageField source="src" />
              </ImageInput>
            ) : (
              <RadioButtonGroupInput
                label="Category"
                source="category"
                formClassName={classes.wide}
                fullWidth
                choices={categoryChoices}
                {...rest}
              />
            )
          }
        </FormDataConsumer>
        <SectionTitle label="resources.tools.fieldGroups.config" />
        <BooleanInput
          label="resources.labels.isActive"
          source="isActive"
          defaultValue={false}
        />
        <DateInput
          label="Start date"
          source="startDate"
          className={classes.first_in_a_row}
          formClassName={classes.first_in_a_row}
          helperText="resources.labels.startDate"
          locales="en-GB"
        />
        <DateInput
          label="End date"
          source="endDate"
          className={classes.first_in_a_row}
          formClassName={classes.next_in_a_row}
          helperText="resources.labels.endDate"
          locales="en-GB"
        />
        <BooleanInput
          label="resources.labels.isPremium"
          source="isPremium"
        />
        <RadioButtonGroupInput
          label="resources.labels.eligibility"
          formClassName={classes.wide}
          fullWidth
          source="eligibility"
          choices={eligibilityChoices}
          defaultValue={'both'}
        />
        <SectionTitle label="resources.tools.fieldGroups.loyalty_reward" />
        <FormDataConsumer>
          {({ formData, ...rest }) =>
            formData.type === 'badges' && (
              <Fragment>
                <NumberInput
                  label="resources.labels.rewardAmount"
                  source="loyalty.value"
                  validate={validateReward}
                  defaultValue={1}
                />
              <SelectInput
                  label="resources.labels.rewardType"
                  source="loyalty.type"
                  choices={loyaltyRewardBadges}
                  defaultValue='badges'
                  value='badges'
                  validate={required()}
                />
                <br/>
                <RadioButtonGroupInput
                  label="resources.labels.expiration"
                  formClassName={classes.wide}
                  fullWidth
                  source="loyalty.expireAfter"
                  choices={expirationChoices}
                  validate={required()}
                  {...rest}
                />
              </Fragment>
            )
          }
        </FormDataConsumer>
        <FormDataConsumer>
          {({ formData, ...rest }) =>
            formData.type !== 'badges' && (
              <Fragment>
                <NumberInput
                  label="resources.labels.rewardAmount"
                  helperText="resources.labels.pointsExpireHelp"
                  source="loyalty.value"
                  validate={validateReward}
                  defaultValue={1}
                />
              <SelectInput
                  label="resources.labels.rewardType"
                  source="loyalty.type"
                  choices={loyaltyRewardPoints}
                  defaultValue='points'
                  value='points'
                  validate={required()}
                />
                <BooleanInput
                  label="resources.labels.cooldown"
                  source="hasCooldown"
                />
                {formData.hasCooldown && (
                  <RadioButtonGroupInput
                    label="resources.labels.cooldownHelp"
                    source="cooldownAfter"
                    formClassName={classes.wide}
                    fullWidth
                    choices={cooldownChoices}
                  />
                )}
              </Fragment>
            )
          }
        </FormDataConsumer>
      </SimpleForm>
    </Create>
  )
}

const requiredValidate = [required()]

const SectionTitle = ({ label }: { label: string }) => {
  const translate = useTranslate()

  return (
    <Typography variant="h6" gutterBottom>
      {translate(label)}
      <span
        style={{
          width: '3rem',
          height: '0.125rem',
          display: 'block',
          background: '#f50057',
          marginTop: '0.25rem',
        }}
      ></span>
    </Typography>
  )
}

const Separator = () => <Box pt="1.5em" />

export default ToolCreate
